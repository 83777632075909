.App {
  padding: 20px;

  & > table {
    margin: 100px auto 20px;
    color: #ccc;
    td {
      padding: 2px 10px;
    }
  }
}
