.status {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 100px;
  background: #039;
  color: #fff;
  width: 150px;
  height: 150px;

  & > * {
    margin: 0;
    line-height: 1;
  }

  h2 {
    font-size: 16px;
    background: white;
    padding: 2px 4px 2px;
    border-radius: 4px;
    font-weight: 600;
  }

  h3 {
    font-size: 32px;
    margin: 15px 0 2px;
  }

  p {
    margin-bottom: 12px;
  }
}
